// globals
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: Roboto, "sans-serif";
  font-size: 100%;
  font-weight: 400;
  line-height: 1.5;
  color: $light-black;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.form-control:focus,
.form-select:focus,
.btn:focus,
a:focus {
  box-shadow: none !important;
}

.form-label {
  font-weight: 500;
}

.bg-aepc {
  background: linear-gradient(to bottom right, #235aaa, #5c91e0) !important;
}

.bg-aepc-dark {
  background-color: $primary-color-dark !important;
}

.btn-aepc-dark {
  background-color: $primary-color-dark !important;
  color: $white !important;
}

.btn-aepc-light {
  background-color: rgba($input-bg-grey, 0.8) !important;
  color: $light-black !important;
  border: 2px solid rgba($input-bg-grey, 1);
}

.text-aepc {
  color: $primary-color !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.input-bg-grey {
  background-color: $input-bg-grey !important;
}

.text-grey {
  color: $light-grey !important;
}

.form-Modal-label {
  font-size: 0.9rem !important;
}

.cell-padding {
  padding: 1.2rem 0.2rem !important;
}

.weather-cell {
  background-color: transparent !important;
  border: 1px solid transparent !important;
  text-align: center;
  font-size: 14px;
  min-width: 80px;
}

.weather-dataCell {
  background-color: transparent !important;
  border: 1px solid transparent !important;
  text-align: center;
  color: $text-grey !important;
  // border: 1px solid red !important;
  padding-top: 0 !important;
}

.padding-10 {
  padding: 1.5rem 0 !important;
}

.pl-2 {
  padding-left: 0.5rem;
}

.weatherStatsCard {
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding: 1rem 1.5rem;
  border-radius: 5px;
  gap: 10px;

  .date-temp {
    display: flex;
    flex-direction: column;
    background-color: $white;
  }

  .weatherDetails {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.5rem;

    .weatherIconContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }
}

.btn-light-blue {
  background-color: $light-blue !important;
  border-radius: 9px;
  height: 44px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  border: 0 !important;
  color: $light-black !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.form-inline {
  .form-control {
    display: inline-block !important;
    width: auto;

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 1px solid $border-grey;
    }

    &:last-child {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.w-min-200 {
  min-width: 200px !important;
}

.w-max-500 {
  max-width: 500px !important;
}

.pressable {
  cursor: pointer;
}

.br-60 {
  border-radius: 60px !important;
}

//recharts
.recharts-text.recharts-label tspan {
  text-anchor: middle;
}

// sidebar
.sidebar {
  position: fixed;
  height: 100%;
  width: 240px;
  z-index: 999;
  overflow: hidden;
  background: $white;
  padding: 20px;
  transition: 0.5s;

  .logo {
    margin-top: 10px;
  }

  .list {
    margin-top: 35px;
  }

  .sibebar-link {
    &:hover {
      background-color: $lighter-grey;
    }

    a {
      width: 100%;
      display: flex;
      text-decoration: none;
      position: relative;

      &.active {
        color: $active-color;
      }

      .icon {
        color: $primary-color;
        min-width: 60px;
        height: 60px;
        line-height: 60px;
        text-align: center;
      }

      .title {
        color: $light-black;
        font-size: 18px;
        height: 60px;
        font-weight: 500;
        line-height: 60px;
      }
    }
  }
}

.main {
  position: relative;
  width: calc(100% - 240px);
  left: 240px;
  padding: 20px;

  &.horizontal-fluid {
    width: 100%;
    left: 0;
    padding: 20px;
    z-index: 1000;
    background-color: #fff;

    .main-content {
      margin-top: 50px;
    }
  }
}

// navbar
.navbar {
  width: 100%;
  height: 100px;

  .title-wrap {
    margin-left: 20px;

    h1 {
      font-size: 27px !important;
      font-weight: 700;
    }
  }

  .user {
    button {
      background-color: $white !important;
      border: 0;
      box-shadow: none !important;
      display: inline-flex;

      &:active {
        box-shadow: none !important;
        border: none !important;
      }

      &::after {
        margin-top: 27px;
        font-size: 24px;
        color: $light-grey;
      }
    }

    .name {
      padding-top: 19px;
      padding-left: 10px;
      color: $light-grey;
      font-size: 16px;
      font-weight: 500;
    }
  }
}

// content wrapper
.main-content {
  margin: 20px 0;
}

// go back
.go-back {
  button {
    font-size: 14px;
    font-weight: 500;
  }

  svg {
    margin-right: 5px;
    font-weight: 500;
  }
}

//page title
.page-title {
  h2 {
    font-size: 24px;
  }
}

//divider
.divider {
  border: none;
  height: 2px !important;
  margin: 0;
  flex-shrink: 0;
  background-color: $lighter-grey;
  opacity: 1;

  &.medium {
    height: 3px !important;
  }

  &.large {
    height: 5px !important;
  }

  &.dark {
    background-color: $light-black;
  }

  &.white {
    background-color: $white;
  }
}

// sign-in
.sign-in {
  .logo {
    transform: scale(1.5);
  }
}

// dashboard
.cards-container {
  .card {
    border: 0 !important;
    border-radius: 18px !important;
    height: 100% !important;

    .card-title {
      font-size: 26px;
      margin-bottom: 10px;
      font-weight: bold;
    }

    .mega-figure {
      font-size: 40px;
      font-weight: bold;
    }

    .mini-figure {
      font-size: 20px;
    }

    .stat-title {
      font-weight: 500;
      margin: 0;
    }
  }

  .overview-card {
    background-image: linear-gradient(to right, #310dfdd3, #639bfb);

    * {
      color: $white;
    }
  }

  .project-card {
    border: 0 !important;
    border-radius: 18px !important;
    background-color: $lighter-grey;

    .btn {
      font-size: 14px !important;
    }
  }
}

.farmerDetail__thead th {
  font-weight: 500;
  font-size: 16px;
  color: $light-grey;
}

.farmerDetail__tbody {
  td {
    font-size: 14px;
    font-weight: 500;
    font-size: 16px;
  }

  span {
    color: $light-grey;
    font-size: 13px;

    svg {
      margin-right: 5px;
    }
  }
}

.filters {
  .filters-header {
    display: inline-block;
    border-bottom: 1px solid $light-grey;

    &:hover {
      cursor: pointer;
    }

    h6 {
      font-size: 16px;
      color: $light-grey;
      font-weight: 400;
      margin-bottom: 2px;
    }

    svg {
      margin-left: 10px;
    }
  }

  .search-bar {
    background-color: #eeecec;
    border: 0;
  }

  .input-group {
    span {
      border: 0;
      background-color: $input-bg-grey;
      color: $light-grey;
    }
  }
}

.custom-progress-bar {
  height: 7px !important;
  color: $primary-color !important;
}

.organization-select {
  border: none !important;
  padding-left: 0 !important;
  font-size: 18px;
  height: 60px;
  font-weight: 500;
  line-height: 60px;
}

//scroll table
.scroll_table {
  height: 425px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.scroll_table:hover {
  overflow-y: scroll;
}

.scroll_table::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

//Project Details table
.gi_table tr {
  margin-top: 150px;
}

.gi_table_spacing {
  padding-left: 100px;
}

//Project Status Nav
.nav__projectStatus {
  text-decoration: none;
  color: black;
  font-size: 15px !important;
}

.nav__projectStatus:hover {
  color: black;
}

.outline_info {
  padding: 5px;
  margin: 0px;
}

.outline_info:hover {
  background-color: #dceaff;
  border-radius: 10px;
  color: black !important;
}

.outline_edit {
  background-color: steelblue;
  border-radius: 100%;
  color: white;
  padding: 4px;
}

.general_info_icons {
  background-color: rgb(239, 238, 238);
  color: rgb(138, 136, 136);
  border-radius: 100%;
  padding: 6px;
  border: 1px solid #828282;
}

//modal
.border-radius-2 div {
  border-radius: 10px !important;
}

//progressBar of Registration
.progressBar_reg {
  height: 10px !important;
}

//meter details
.meter-details {
  .title {
    font-size: 14px;
    color: $light-grey;
    font-weight: 500;
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 0;
  }

  .farmer-detail {
    img {
      margin-right: 20px;
      border-radius: 6px;
      height: 150px;
      width: 150px;
      object-fit: cover;
      object-position: center;
    }

    h3 {
      font-size: 24px;
    }
  }

  .sys-status {
    p {
      font-size: 14px;
    }
  }

  .graph {
    border: 1px solid $border-grey;
    border-radius: 18px;

    .header {
      background-color: $lighter-grey;
      padding: 25px 10px;
      font-weight: bold;
      font-size: 24px;
      border-radius: 18px 18px 0 0;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      height: 86px;

      span {
        padding: 0 12px;
      }

      .g-title {
        position: relative;

        &::after {
          content: "";
          content: "";
          position: absolute;
          width: 2px;
          height: 18px;
          background: $light-black;
          top: 6px;
          right: -2px;
        }
      }
    }

    @media only screen and (max-width: 653px) {
      .graph-box {
        display: flex;
        flex-direction: column;
      }

      .meter-detail {
        // margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      // .meter_Icons{
      //   margin-right: 0px;
      // }
    }

    .stat-header {
      color: $primary-color;

      h6 {
        color: $light-black;
        font-size: 14px;
      }

      .stat-box {
        margin-left: 2px;

        h5 {
          font-size: 26px;
          margin-bottom: 0;
        }

        p {
          font-size: 14px;
          font-weight: 500;
          color: $light-black;
          margin-bottom: 0;
        }
      }
    }

    .refresh-btn {
      // display: block;
      // margin-left: auto;
      margin: 0px;
      font-size: 14px;
      font-weight: 500;
      border: 3px solid #4173bd;
      border-radius: 9px;
      // width: 177px;
      // height: 44px;
      // margin-right: 32px;
      // margin-top: 35px;
      display: flex;
      align-items: center;
      justify-self: center;
      justify-content: center;
      font-weight: bold;
      font-size: 16px;
    }

    .footer {
      ul {
        display: grid;

        &.sys-params {
          grid-template-columns: repeat(2, 1fr);
          column-gap: 10px;
        }

        li > span {
          cursor: pointer;

          &.active {
            text-decoration: underline;
            font-weight: 500;
          }
        }
      }
    }

    .date-filter {
      display: flex;
      max-width: 300px;

      select {
        margin-right: 5px;
      }
    }
  }
}

//pagination
.activePagination a {
  background-color: #235aaa !important;
}

.page-item a {
  color: black;
  margin-right: 20px;
  margin-top: 50px;
  background-color: #eeecec;
  border-radius: 3px;
}

.upload_button_container {
  display: flex;
  border: 1px solid rgb(223, 223, 223);
  border-radius: 5px;
  flex-direction: row;
  width: 100%;
  padding: 20px;
  gap: 20px;
  cursor: pointer;
  align-items: center;
  // border: 1px solid red;
}

.break-label {
  margin-right: 30px;

  margin-top: 50px;
}

//toggleButton
.Btn-Blue-BG .active {
  background-color: #235aaa !important;
  color: #eeecec !important;
}

.Btn-Blue-BG2 .active {
  background-color: #235aaa !important;
  color: #eeecec !important;
}

//projectDetails

.solarArray {
  padding-left: 134px !important;
}

.pumpDetails {
  padding-left: 192px !important;
}

.controllerDetails {
  padding-left: 173px !important;
}

.circuitBreaker {
  padding-left: 223px !important;
}

.spd {
  padding-left: 107px !important;
}

.cable {
  padding-left: 225px !important;
}

.airTermination {
  padding-left: 76px !important;
}

.downConductor {
  padding-left: 133px !important;
}

.earthElectrode {
  padding-left: 167px !important;
}

.mountingStructure {
  padding-left: 102px !important;
}

.projectDetailNav .active {
  color: black !important;
}

.custom-tooltip {
  background-color: white;
  padding: 1rem;
  box-shadow: 0px 0px 10px #888888;
}

.delete-btn {
  display: none;
}

.folder-btn:hover + .delete-btn,
.delete-btn:hover {
  // margin-left: -30px;
  margin-bottom: 50px;
  display: inline;
  position: absolute;
  top: 0px;
  right: 5px;
}

//uploded icons
.uploaded__icons {
  width: 26px;
  height: 15px;
  padding: 4px;
  text-align: center;
}

//icon_location
.icon_location {
  width: 21px;
  height: 24px;
  padding: 6px 4px 2px 4px;
  text-align: center;
}

.uploaded__icons__border {
  background-color: #eeecec;
  border: 1px solid #828282;
  border-radius: 50%;
  text-align: center;
  width: 30px;
  height: 30px;
  margin-right: 8px;
  display: inline-block;
}

//general Info
@media only screen and (max-width: 1200px) {
  .generalInfoRow {
    margin-bottom: 290px;
  }

  .generalInfo_Name {
    margin-top: 15px;
  }

  .installation_editDetails {
    margin-bottom: 10px;
  }
}

.uploaded_files {
  border: 4px #eeecec solid;
  border-radius: 15px;
  margin: 10px;
}

//files and logs
@media only screen and (max-width: 768px) {
  .uploaded_files {
    margin-bottom: 10px;
    padding-bottom: 50px !important;

    border: 4px #eeecec solid;
  }
}

//folder_Row
@media only screen and (min-width: 1203px) {
  .folder_Row {
    height: 100px;
  }
}

//space_folder

@media only screen and (max-width: 766px) {
  .space_folder {
    margin-left: 12px;
    padding-bottom: 50px;
  }
}

//upload_Button
.upload_Button {
  border: 4px #adadad dashed;
  border-radius: 15px;
  margin-top: -40px;
  width: 300px;
  height: 70px;
  display: flex;
  align-items: center;
}

.register_btn {
  padding: 2px;
}

//register_btnGroup
// @media only screen and (max-width:1285px){
//  .register_btnGroup{
//    display: flex;
//    flex-direction: column;

//  }

//  .register_btn{
//    margin-bottom: 31px;
//  }
// }

// @media only screen and (max-width:1451px ){
//   .quickFilter_btnGroup{
//     display: flex;
//     flex-direction: column;

//   }

//   .quickFilter_btn{
//     margin-bottom: 10px;
//   }
//  }

//folder_contents
.folder_contents {
  text-align: left;
}

.info-folder-contents {
  margin-left: -30px;
}

@media only screen and (max-width: 767px) {
  .folder_contents {
    text-align: center !important;
  }
}

@media only screen and (max-width: 1301px) {
  .info-folder-contents {
    margin-left: 0px;
  }
}

// //view_projectBtnGroup
// @media only screen and (max-width:1440px){
// .view_projectBtnGroup{
//   display: flex;
//   flex-direction: column;
//  }

//  .view_projectBtn{
//    margin-bottom: 8px;
//    margin-left: 10px;
//  }

// }

//form_container
.form_container {
  width: 80%;
  margin-right: 150px;
}

//form_cover
.form_cover {
  width: 92% !important;
}

//Icons

.uploadIcon {
  margin-right: 20px;
}

.generalInfoNav_Icons {
  margin-right: 12px;
}

.generalInfo_Icons {
  margin-right: 9px;
}

//folder_Name
.folder_Name {
  display: flex;
  justify-content: flex-start;
}

.folder_NameIcon {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .folder_Name {
    display: flex;
    justify-content: center;
  }
}

//toggle_btns

.toggle_btns {
  border-radius: 9px;
  height: 100%;
  width: 100%;
  // margin: 2px;
  border: 3px solid #4173bd;
  font-weight: 700;
}

//save_btn
.save_btn {
  border-radius: 9px;
  width: 178px;
  height: 44px;
  font-weight: 700;
}

//folder_shift
@media only screen and (max-width: "") {
  .folder_shift {
    margin-right: -15px;
  }
}

//meter_Icons
.meter_Icons {
  color: #4173bd;
}

//  @media only screen and (min-width:1435px){
//  .meter_Icons_co2{
//    margin-left: -40px;
//  }

//  .meter_Icons_Thunder{
//   margin-left: -40px !important;
// }
// }
.meter-value {
  color: $primary-color;
}

.meter-icon-text {
  font-weight: bold !important;
  font-size: 14px !important;
  color: $light-black;
  color: #4173bd;
}

//form-design

.form-design {
  background: #eeecec;
  border-radius: 9px;
  height: 46px;
}

.select-toggle {
  border-bottom-width: 0;
  background-color: #eeecec;
  border-radius: 9px;
  height: 46px;
}
//For a seletor that needs a soecific color
.coloured-select-toggle {
  color: #4173bd; /* This will set the text color of the select itself */
}

.coloured-select-toggle option {
  color: #4173bd; /* This sets the color of the options in the dropdown */
}

//signIn-card

.signIn-card {
  box-shadow: 15px 15px 18px #245092, 33px 23px 39px #245092;
  // border-radius: 36px !important;
  // width: 498px !important;
  // height: 601px !important;
}

.custom-placeholder::placeholder {
  color: #4173bc;
}
